import http from './http-common'

export async function getProducts (filters) {
  return await http.get(`/staff/products?sort=createdDate,desc&name=${filters.name}&start=${filters.start}&end=${filters.end}&size=${filters.size}&page=${filters.page}`)
}

export async function getAllProducts () {
  return await http.get('/staff/products/all')
}

export async function getProduct (id) {
  return await http.get(`/staff/products/${id}`)
}

export async function createProduct (data) {
  return await http({
    method: 'POST',
    url: '/staff/products',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function updateProduct (id, data) {
  return await http({
    method: 'PUT',
    url: `/staff/products/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function deactivateProduct (id) {
  return await http.put(`/staff/products/${id}/deactivate`)
}

export async function activateProduct (id) {
  return await http.put(`/staff/products/${id}/activate`)
}

export async function deleteProduct (id) {
  return await http.delete(`/staff/products/${id}`)
}

export async function deleteImageUsingPUT (productId, imageUrl) {
  const formData = new FormData()
  formData.append('productId', productId)
  formData.append('imageUrl', imageUrl)
  return await http({
    method: 'PUT',
    url: `/staff/products/delete-product-image/${productId}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function uploadSizeGuideUsingPUT (id, data) {
  return await http({
    method: 'PUT',
    url: `/staff/product/upload-size-guide/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function getProductOptionTypes (id) {
  return (await http.get(`/staff/products/option-type/${id}`)).data
}

export async function getProductOptions (id) {
  return (await http.get(`/staff/products/option/${id}`)).data
}

export async function getProductOptionsValue (id, productOptionTypeId) {
  return (await http.get(`/staff/products/product-option/${id}?productOptionTypeId=${productOptionTypeId}`)).data
}

export async function getPromotionalProducts () {
  return (await http.get('/staff/products/get-promotional-products')).data
}

export async function setPromotionalProducts (productId) {
  return (await http.post(`/staff/products/${productId}/set-promotional-product`)).data
}

export async function setMultiPromotionalProducts (productIds) {
  return await http({
    method: 'POST',
    url: '/staff/products/set-promotional-products',
    data: productIds,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export async function removePromotionalProducts (productId) {
  return (await http.post(`/staff/products/${productId}/remove-promotional-product`)).data
}
export async function getSizeGuideHistory () {
  return (await http.get('/staff/products/get-size-guide-history')).data.data
}
export async function updateSortingOrderForProducts (json) {
  return (await http.post('/staff/products/sorting-order-for-products', json)).data
}
export async function updateProductInventoryById (productId, inventory) {
  return (await http.post(`/staff/products/${productId}/set-inventory-for-product?inventory=${inventory}`)).data
}
export async function getInventoryByProId (productId) {
  return (await http.get(`/staff/products/${productId}/inventory-for-product`)).data.data
}
export async function getProductSearchByProId (search) {
  return (await http.get(`/staff/products/search-by-id/${search}`)).data.data
}
export async function updateProductInventory (productOptionId, inventory) {
  const formData = new FormData()
  formData.append('productOptionId', productOptionId)
  formData.append('inventory', inventory)
  return await http({
    method: 'POST',
    url: `/staff/products/${productOptionId}/set-inventory-for-option?inventory=${inventory}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}
